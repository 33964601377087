<template>
  <v-timeline
    aut-timeline
    v-bind="displayAttributes"
    :class="classAttributes"
    :dense="isShortFormat"
  >
    <v-timeline-item
      v-bind="itemAttributes"
      v-for="(event, index) in events"
      :key="index"
    >
      <template v-slot:opposite v-if="!isShortFormat">
        <v-chip color="primary" outlined aut-timeline-opposite>
          <v-icon left> mdi-clock </v-icon> {{ event.time }}
        </v-chip>
      </template>
      <v-card v-bind="cardAttributes" v-if="!isShortFormat" aut-timeline-card>
        <v-card-title class="primary--text" v-html="event.name"></v-card-title>
        <v-card-subtitle v-if="isDense" v-html="event.time"></v-card-subtitle>
        <v-divider v-if="event.details"></v-divider>
        <v-card-text v-html="event.details"></v-card-text>
      </v-card>
      <template v-else>
        <v-menu transition="fab-transition" origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="py-1 px-2"
              v-on="on"
              v-bind="attrs"
              aut-timeline-short
            >
              <span class="behavior_single_line">{{ event.name }}</span>
              <span class="text-caption behavior_single_line">
                {{ event.shortTime }}
              </span>
            </v-card>
          </template>
          <v-card aut-short-menu>
            <v-card-title
              class="primary--text"
              v-html="event.name"
            ></v-card-title>
            <v-card-subtitle v-html="event.time"></v-card-subtitle>
            <v-divider v-if="event.details"></v-divider>
            <v-card-text v-html="event.details"></v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
export default {
  name: "Timeline",
  props: {
    displayAttributes: Object,
    classAttributes: Array,
    events: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    itemAttributes() {
      return this.displayAttributes?.item || {};
    },
    isShortFormat() {
      return this.displayAttributes?.format == "short";
    },
    cardAttributes() {
      return this.displayAttributes?.card || {};
    },
    isDense() {
      return this.displayAttributes?.dense == true;
    },
  },
};
</script>
